import React from "react";
import ButtonNavbar from "./ButtonNavbar";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { IconButton, SwipeableDrawer, useMediaQuery } from "@mui/material";
import { setLogout, setTheme } from "state";
import DarkModeSharpIcon from "@mui/icons-material/DarkModeSharp";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";

export default function Navbar() {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const mode = useSelector((state) => state.mode);
  const language = useSelector((state) => state.language);
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <>
      {isNotMobileScreen ? (
        <nav
          style={{
            backdropFilter: "blur(13px)",
            WebkitBackdropFilter: "blur(13px)",
            backgroundColor:
              mode === "dark"
                ? hexToRGBA(palette.neutral.darker, 0.7)
                : hexToRGBA("#ffffff", 0.7), //try to implement pallette
            borderBottom: `1px solid ${
              mode === "dark" ? palette.neutral.main : palette.neutral.medium
            }`,
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "2", //for some reason some mui components will overlap at 0, others at 1, therefore this is set to 2
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "0 1rem",
            }}
          >
            <div style={{ alignSelf: "center", marginRight: "3rem" }}>
              <IconButton
                onClick={() => {
                  dispatch(setTheme());
                }}
                color="primary"
                name="Set Theme"
              >
                <DarkModeSharpIcon />
              </IconButton>
            </div>
            <ButtonNavbar text={"home"} />
            <ButtonNavbar
              text={language === "german" ? "über_uns" : "about_us"}
            />
            <Link
              to={"/"}
              style={{
                color: palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                minWidth: "16%",
              }}
            >
              <img
                src="/assets/logoWindhose.svg"
                alt="WKR Logo"
                style={{ width: "60px", cursor: "pointer" }}
              />
            </Link>
            <ButtonNavbar
              text={language === "german" ? "kontakt" : "contact"}
            />
            <ButtonNavbar
              text={language === "german" ? "projekte" : "projects"}
            />
            <div
              style={{
                alignSelf: "center",
                marginLeft: "3rem",
              }}
            >
              <div style={{ width: "40px", height: "40px" }}></div>
              {/* Language option, above is the placeholder div
              <IconButton
                onClick={() => {
                  dispatch(setLanguage());
                }}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              >
                <img
                  src={
                    language === "german"
                      ? "/assets/ger.svg"
                      : "/assets/eng.svg"
                  }
                  alt="Language"
                  style={{ width: "100%" }}
                />
              </IconButton> */}
            </div>
          </div>
        </nav>
      ) : (
        <nav
          style={{
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            backgroundColor:
              mode === "dark"
                ? hexToRGBA(palette.neutral.darker, 0.7)
                : hexToRGBA("#ffffff", 0.7), //try to implement pallette
            borderBottom: `1px solid ${palette.neutral.main}`,
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "2",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={toggleDrawer(true)}
              sx={{
                position: "absolute",
                top: 3,
                left: 3,
                p: "2rem",
              }}
            >
              <MenuSharpIcon />
            </IconButton>
            <Link
              to={"/"}
              style={{
                margin: "0 20vw",
                padding: "0.5rem 1rem",
                color: palette.primary.main,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/logoWindhose.svg"
                alt="WKR Logo"
                style={{ width: "70px", cursor: "pointer", margin: "1rem 0" }}
              />
            </Link>
          </div>
          <>
            <SwipeableDrawer
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <div
                style={{
                  backgroundColor: mode === "dark" && palette.neutral.darker,
                  minHeight: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Link
                  to={"/"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem 0",
                    margin: "1rem 0",
                  }}
                  onClick={() => {
                    setTimeout(() => {
                      setDrawerOpen(false);
                    }, 400);
                  }}
                >
                  <img
                    src="/favicon.svg"
                    alt="WKR Logo"
                    style={{ width: "30%" }}
                  />
                </Link>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ButtonNavbar
                    text={"home"}
                    isDrawerOpen={isDrawerOpen}
                    setDrawerOpen={setDrawerOpen}
                  />
                  <ButtonNavbar
                    text={language === "german" ? "über_uns" : "about_us"}
                    isDrawerOpen={isDrawerOpen}
                    setDrawerOpen={setDrawerOpen}
                  />
                  <ButtonNavbar
                    text={language === "german" ? "kontakt" : "contact"}
                    isDrawerOpen={isDrawerOpen}
                    setDrawerOpen={setDrawerOpen}
                  />
                  <ButtonNavbar
                    text={language === "german" ? "projekte" : "projects"}
                    isDrawerOpen={isDrawerOpen}
                    setDrawerOpen={setDrawerOpen}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "auto",
                    marginBottom: "2rem",
                    gap: "0.5rem",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      dispatch(setTheme());
                    }}
                    color="primary"
                    size="large"
                    name="Set Theme"
                  >
                    <DarkModeSharpIcon />
                  </IconButton>
                  {token && (
                    <IconButton
                      onClick={() => {
                        dispatch(setLogout());
                      }}
                      color="primary"
                      size="large"
                    >
                      <LogoutSharpIcon />
                    </IconButton>
                  )}
                  {/* Language option
                  <IconButton
                    onClick={() => {
                      dispatch(setLanguage());
                    }}
                    sx={{
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <img
                      src={
                        language === "german"
                          ? "/assets/ger.svg"
                          : "/assets/eng.svg"
                      }
                      alt="Language"
                      style={{ width: "100%" }}
                    />
                  </IconButton> */}
                </div>
              </div>
            </SwipeableDrawer>
          </>
        </nav>
      )}
    </>
  );
}
