import { styled } from "@mui/material/styles";

const serverUrl =
  "https://windkraft-regional-server-076e810dcef0.herokuapp.com";
//const serverUrl = "http://localhost:5001"; // Development
const amazonUrl = "https://windkraft-regional.s3.amazonaws.com";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function bytesToMB(bytes) {
  const MB = bytes / (1024 * 1024);
  return MB.toFixed(1);
}

const defaultGsapFrom = {
  y: 20,
  opacity: 0,
};

const defaultGsapTo = {
  y: 0,
  opacity: 1,
  stagger: {
    amount: 0.25,
  },
};

export {
  serverUrl,
  amazonUrl,
  VisuallyHiddenInput,
  bytesToMB,
  defaultGsapFrom,
  defaultGsapTo,
};
