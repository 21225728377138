import { createContext, useContext, useState } from "react";
import useFetch from "hooks/useFetch";
import { serverUrl } from "functions/values";
import { useSelector } from "react-redux";

const ReportContext = createContext();

export const useReports = () => useContext(ReportContext);

export const ReportsProvider = ({ children }) => {
  const [reports, setReports] = useState([]);
  const { isPending, fetchData } = useFetch();

  const reportToken = useSelector((state) => state.reportToken);
  const editReportToken = useSelector((state) => state.editReportToken);
  const token = useSelector((state) => state.token);

  const fetchReports = async () => {
    await fetchData(
      `${serverUrl}/reports`,
      editReportToken || reportToken || token,
      (result) => {
        setReports(result);
      },
      () => {},
      true
    );
  };

  return (
    <ReportContext.Provider
      value={{ reports, setReports, isPending, fetchReports }}
    >
      {children}
    </ReportContext.Provider>
  );
};
