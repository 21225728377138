import {
  Box,
  Divider,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import PageTitle from "components/PageTitle";
import TextContent from "components/TextContent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import ContentModule from "components/ContentModule";
import { v4 as uuidv4 } from "uuid";
import { addProjectState, setProjectState } from "state";
import DropDownComponents from "components/DropDownComponents";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { defaultGsapFrom, serverUrl } from "functions/values";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import LoadingLogo from "components/Loading/LoadingLogo";
import UploadCancelCMS from "components/UploadCancelCMS";

export default function Projects() {
  const dispatch = useDispatch();
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  const token = useSelector((state) => state.token);
  let projects;
  projects = useSelector((state) => state.projects);

  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  useEffect(() => {
    document.title = "WKR | Projects";
    getProjects();
  }, []); //eslint-disable-line

  const [loading, setLoading] = useState(true);
  const [orderedProjects, setOrderedProjects] = useState([]);

  useGSAP(() => {
    if (!loading) {
      gsap.fromTo(".projectContainer", defaultGsapFrom, {
        y: 0,
        opacity: 1,
        stagger: {
          amount: 0.5,
        },
      });
    }
  }, [loading]);

  const [duplicateError, setDuplicateError] = useState(false);
  const [newProjectInput, setNewProjectInput] = useState(false);
  const [formState, setFormState] = useState({
    projectName: "",
  });

  const handleChange = (e) => {
    if (duplicateError) setDuplicateError(false);
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  // Get Projects
  async function getProjects() {
    try {
      const response = await fetch(`${serverUrl}/projects/all`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        dispatch(setProjectState(data));
      } else {
        const data = await response.json();
        console.log(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!projects || projects.length === undefined || projects.length === 0)
      return;
    const sortedProjects = [...projects].sort((a, b) => {
      return a.projectName.localeCompare(b.projectName);
    });
    let orderedProjectsArray = [];

    sortedProjects.forEach((project, i) => {
      const existingTitleIndex = orderedProjectsArray.findIndex(
        (subsection) => subsection[0].title === project.projectName
      );

      if (existingTitleIndex === -1) {
        orderedProjectsArray.push([{ title: project.projectName }, project]);
      } else {
        orderedProjectsArray[existingTitleIndex].push(project);
      }
    });

    orderedProjectsArray.sort((a, b) => a[0].title.localeCompare(b[0].title));
    setOrderedProjects(orderedProjectsArray);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [projects]);

  // Content
  const [contents, setContents] = useState([]);
  const addContent = (type) => {
    const uniqueId = uuidv4();
    let newComponent = {
      uuid: uniqueId,
      orderNumber: contents.length + 1,
      contentType: type,
      content: "",
    };

    if (type === "video" || type === "image") {
      newComponent.imgVidDescription = "";
    }

    if (type === "image") {
      newComponent.images = [];
      newComponent.imgDisplaySize = "large";
    }

    if (type === "video") {
      newComponent.video = "";
      newComponent.isAutoplay = false;
      newComponent.isControls = true;
      newComponent.isLoopingVideo = false;
      newComponent.isMuted = false;
    }

    if (type === "listBullet" || type === "listNumbered") {
      newComponent.list = [];
    }

    setContents([...contents, newComponent]);
  };

  const changeContents = (updatedContents) => {
    setContents(updatedContents);
  };

  const updateOrder = (oldNumber, newNumber) => {
    const updatedContents = contents.map((content) => {
      if (content.orderNumber === oldNumber) {
        return { ...content, orderNumber: newNumber };
      } else if (content.orderNumber === newNumber) {
        return { ...content, orderNumber: oldNumber };
      }
      return content;
    });

    setContents(updatedContents);
  };

  // Post Project
  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function getRandomUUID() {
    return uuidv4();
  }

  const postNewProject = async () => {
    const formData = new FormData();
    formData.append("projectName", formState.projectName);
    let contentsForServer = contents;

    // Image management
    // Cropped images
    const newContents = contentsForServer.map((content) => {
      if (content.images) {
        const newImages = content.images.map((image, i) => {
          if (image.cropped) {
            const croppedImage = dataURLtoFile(
              image.dataURL,
              `${
                content.orderNumber
              }-contentOrder-${i}-index-${getRandomUUID()}-${Date.now()}.${
                image.cropped
              }`
            );
            formData.append("images", croppedImage);
            return croppedImage.name;
          }
          return image;
        });
        content.images = newImages;
      }
      return content;
    });

    contentsForServer = newContents;

    contentsForServer.forEach((content) => {
      if (content.images) {
        content.images.forEach((image, i) => {
          if (image.file instanceof File) {
            const file = image.file;
            const extension = file.name.split(".").pop();
            const newName = `${
              content.orderNumber
            }-contentOrder-${i}-index-${getRandomUUID()}-${Date.now()}.${extension}`;

            const newFile = new File([file], newName, { type: file.type });
            formData.append("images", newFile);
            content.images[i] = newName;
          }
        });
      }
    });
    // Image management

    // Video management
    contentsForServer.forEach((content) => {
      if (content.video) {
        const file = content.video.file;
        const extension = file.name.split(".").pop();
        const newName = `${
          content.orderNumber
        }-contentOrder-${getRandomUUID()}-${Date.now()}.${extension}`;

        const newFile = new File([file], newName, { type: file.type });
        formData.append("videos", newFile);
        content.video = newName;
      }
    });
    // Video management

    const contentsSerialised = JSON.stringify(contentsForServer);
    formData.append("contents", contentsSerialised);

    try {
      const response = await fetch(`${serverUrl}/projects/post`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        dispatch(addProjectState({ formState }));
        setFormState({
          projectName: "",
        });
        setContents([]);
      } else {
        const data = await response.json();
        console.log(data.error);
        if (data.error.startsWith("E11000")) {
          setDuplicateError(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Content
  const removeContent = (orderNumberToRemove) => {
    const updatedContents = contents
      .filter((content) => content.orderNumber !== orderNumberToRemove)
      .map((content) => {
        if (content.orderNumber > orderNumberToRemove) {
          return { ...content, orderNumber: content.orderNumber - 1 };
        }
        return content;
      });

    setContents(updatedContents);
  };

  return (
    <TextContent isNotMobileScreen={isNotMobileScreen}>
      <div style={{ maxWidth: "800px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: [isNotMobileScreen ? "center" : "end"],
            gap: "1rem",
          }}
        >
          <PageTitle title={isGerman ? "Projekte" : "Projects"} />
          {token && (
            <div>
              <IconButton
                color="primary"
                style={{ position: "relative" }}
                onClick={() => setNewProjectInput(true)}
              >
                <AddCircleSharpIcon />
              </IconButton>
            </div>
          )}
        </div>

        <Divider sx={{ mt: "1rem" }} />

        {newProjectInput && (
          <>
            <form
              style={{ margin: "0 0 2rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                postNewProject();
              }}
            >
              {isNotMobileScreen ? (
                <h3 style={{ margin: "1rem 0 0.5rem" }}>
                  {isGerman ? "Neues Projekt" : "New Project"}
                </h3>
              ) : (
                <h5 style={{ margin: "1rem 0 0.5rem" }}>
                  {isGerman ? "Neues Projekt" : "New Project"}
                </h5>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <div style={{ display: "flex", gap: "1rem" }}>
                  <TextField
                    name="projectName"
                    label={isGerman ? "Projektname" : "Project Name"}
                    variant="standard"
                    fullWidth
                    value={formState.projectName}
                    onChange={handleChange}
                    required
                  />
                </div>
                {duplicateError && (
                  <div style={{ color: "red" }}>
                    {isGerman
                      ? `Fehler! Der Projektname "${formState.projectName}" ist bereits vergeben.`
                      : `Error! The project name "${formState.projectName}" is already taken.`}
                  </div>
                )}
                <DropDownComponents addContent={addContent} />
              </div>
              <div style={{ margin: "1rem 0" }}>
                <Divider />
              </div>
              <Box
                id={"contentsContainer"}
                sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
              >
                {contents &&
                  contents
                    .slice()
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    .map((content) => (
                      <ContentModule
                        key={content.uuid}
                        content={content}
                        contents={contents}
                        changeContents={changeContents}
                        updateOrder={updateOrder}
                        removeContent={removeContent}
                      />
                    ))}
              </Box>
              {contents && contents.length > 0 && (
                <div style={{ margin: "1rem 0" }}>
                  <Divider />
                </div>
              )}
              <UploadCancelCMS setIsInput={setNewProjectInput} />
            </form>
            <Divider />
          </>
        )}

        {!loading &&
          orderedProjects &&
          orderedProjects.map(([_, project]) => {
            return (
              <div
                key={getRandomUUID()}
                style={{
                  margin: "0.7rem 0",
                  fontSize: isNotMobileScreen ? "1.4rem" : "1.1rem",
                }}
                className="projectContainer"
              >
                <NavLink
                  style={{
                    color: palette.primary.main,
                  }}
                  className="underline"
                  to={`/projects/${project.projectName}`}
                >
                  {project.projectName}
                </NavLink>
                <br />
              </div>
            );
          })}
        {loading && (
          <LoadingLogo scale={isNotMobileScreen ? 0.65 : 0.8} mt={"8rem"} />
        )}
      </div>
    </TextContent>
  );
}
