import { Button, CircularProgress, TextField } from "@mui/material";
import { useNotification } from "context/NotificationContext";
import { serverUrl } from "functions/values";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEditReportLogin, setReportLogin } from "state";

export default function ReportLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = useNotification();

  const [loading, setLoading] = useState(false);

  // Form State
  const [formState, setFormState] = useState({
    usernameReports: "",
    passwordReports: "",
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  // Form State

  async function login() {
    setLoading(true);
    try {
      const loggedInResponse = await fetch(`${serverUrl}/auth/reports/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formState),
      });

      const loggedIn = await loggedInResponse.json();

      if (!loggedInResponse.ok) {
        showNotification("error", loggedIn.msg);
        throw new Error("Login Error");
      }

      if (loggedIn.edit) {
        dispatch(
          setEditReportLogin({
            token: loggedIn.token,
          })
        );
        navigate("/reports");
      } else if (loggedIn) {
        dispatch(
          setReportLogin({
            token: loggedIn.token,
          })
        );
        navigate("/reports");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        login();
      }}
      style={{
        marginTop: "10vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <TextField
        inputProps={{ style: { fontSize: "1rem" } }}
        InputLabelProps={{ style: { fontSize: "1rem" } }}
        name="usernameReports"
        label="Nutzername"
        variant="standard"
        fullWidth
        value={formState.projectName}
        onChange={handleChange}
        required
      />

      <TextField
        inputProps={{ style: { fontSize: "1rem" } }}
        InputLabelProps={{ style: { fontSize: "1rem" } }}
        name="passwordReports"
        label="Passwort"
        variant="standard"
        type="password"
        fullWidth
        value={formState.projectName}
        onChange={handleChange}
        required
      />

      {/* <div style={{ color: "red" }}>{errors}</div> */}

      <Button
        variant="outlined"
        type="submit"
        sx={{ fontSize: "1rem" }}
        endIcon={
          loading && <CircularProgress size={"1.2rem"} color="inherit" />
        }
      >
        Login
      </Button>
    </form>
  );
}
