import { Alert, Box, useMediaQuery } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import gsap from "gsap";
import { defaultGsapFrom, defaultGsapTo } from "functions/values";

const NotificationContext = createContext();
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const [notifications, setNotifications] = useState([]);

  const [stacked, setStacked] = useState(true);

  function showNotification(
    type,
    message,
    duration = 5000,
    position = "center"
  ) {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, type, message, position }]);
    setTimeout(() => {
      animateIn(id);
    }, 0);

    setTimeout(() => {
      animateOut(id);
      setTimeout(() => {
        setNotifications((prev) =>
          prev.filter((notification) => notification.id !== id)
        );
        notifications.length === 0 && setStacked(true);
      }, 400);
    }, duration);
  }

  function animateIn(id) {
    gsap.fromTo(`.notif${id}`, defaultGsapFrom, defaultGsapTo);
  }

  function animateOut(id) {
    gsap.to(`.notif${id}`, {
      y: 20,
      opacity: 0,
      ease: "power1.in",
    });
  }

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <Box
        sx={{
          position: "fixed",
          bottom: "2rem",
          width: isNotMobileScreen ? "300px" : "90%",
          left: 0,
          right: 0,
          marginInline: "auto",
        }}
        onMouseEnter={() => {
          setStacked(false);
          // TODO: Freeze timer on hover, make a progress bar for the timer
        }}
        onMouseLeave={() => {
          setStacked(true);
        }}
      >
        {notifications &&
          notifications.map((notification, i) => (
            <Box
              key={notification.id}
              className={"notif" + notification.id}
              sx={{
                opacity: 0,
                zIndex: i,
                display: "block",
                position: "fixed",
                bottom: "2rem",
                left: notification.position === "left" && "2rem",
                right: notification.position === "right" && "2rem",
                width: isNotMobileScreen ? "300px" : "90%",
                marginInline: "auto",
              }}
            >
              <Alert
                severity={notification.type}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  transform: stacked
                    ? `translateY(${(notifications.length - 1 - i) * -10}px) 
                      scale(${1 - (notifications.length - 1 - i) * 0.1})`
                    : `translateY(${
                        (notifications.length - 1 - i) * -55
                      }px) scale(1)`,
                  transition: "transform 0.3s ease",
                }}
              >
                {`${notification.type.toUpperCase()}: ${notification.message}`}
              </Alert>
            </Box>
          ))}
      </Box>
    </NotificationContext.Provider>
  );
};
