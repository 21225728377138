import { useNotification } from "context/NotificationContext";
import { useState } from "react";

export default function useFetch() {
  const showMessage = useNotification();

  const [isPending, setIsPending] = useState(true);

  const fetchData = async (
    url,
    token,
    successFunction = () => {},
    errorFunction = () => {}
  ) => {
    setIsPending(true);

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      successFunction(result);
    } catch (err) {
      console.error("Fetch error:", err);
      errorFunction();
      showMessage("error", err.message);
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, fetchData };
}
