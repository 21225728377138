import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { serverUrl } from "functions/values";
import { useSelector } from "react-redux";
import { useNotification } from "context/NotificationContext";
import DeleteDialog from "./DeleteDialog";
import { useReports } from "context/ReportContext";

export default function ReportDeletingSnackBar({
  deleting,
  setDeleting,
  deleteArray = [],
  setDeleteArray,
  animateCheckboxes,
}) {
  const showNotification = useNotification();
  const editReportToken = useSelector((state) => state.editReportToken);
  const token = useSelector((state) => state.token);
  const { reports, setReports } = useReports();

  const [open, setOpen] = useState(false);

  // delete modal
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  function toggleDeleteModal() {
    setDeleteModal(!isDeleteModalOpen);
  }
  // delete modal

  useEffect(() => {
    if (!deleting) return;
    setOpen(true);
  }, [deleting]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteArray([]);
    setOpen(false);
    animateCheckboxes();

    setTimeout(() => {
      setDeleting(false);
    }, 1000);
  };

  async function deleteReports() {
    try {
      const response = await fetch(`${serverUrl}/reports/delete`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${token || editReportToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          deleteArray,
        }),
      });
      if (response.ok) {
        const deletedCount = await response.json();

        showNotification(
          "success",
          `Erfolgreich ${deletedCount} ${
            deletedCount === 1 ? "Objekt" : "Objekte"
          } gelöscht`
        );

        setReports(
          reports.filter((report) => !deleteArray.includes(report._id))
        );
        handleClose();
      } else {
        const errorMessage = await response.json();
        console.log(errorMessage.message || errorMessage);
        showNotification(
          "error",
          errorMessage.message || errorMessage,
          5000,
          "left"
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  const action = (
    <Box sx={{ display: "flex", gap: "0.5rem" }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={toggleDeleteModal}
        disabled={deleteArray.length === 0}
      >
        Löschen
      </Button>
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        onClick={handleClose}
      >
        Abbruch
      </Button>
    </Box>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        message={
          deleteArray.length === 0
            ? "Keine Objekte ausgewählt"
            : `${deleteArray.length} ${
                deleteArray.length === 1 ? "Objekt" : "Objekte"
              } entfernen?`
        }
        action={action}
      />

      <DeleteDialog
        isDeleteModal={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        del={deleteReports}
        title="Löschen Bestätigen"
        dialogContent={`Sind Sie sicher, dass Sie ${deleteArray.length} ${
          deleteArray.length === 1 ? "Bericht" : "Berichte"
        } löschen möchten?`}
        delButton="Löschen"
      />
    </>
  );
}
