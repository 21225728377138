import { Box, Divider, Typography } from "@mui/material";
import ReportPdfInput from "./ReportPdfInput";
import UploadCancelCMS from "./UploadCancelCMS";
import { useState } from "react";
import { defaultGsapFrom, defaultGsapTo, serverUrl } from "functions/values";
import { useSelector } from "react-redux";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useNotification } from "context/NotificationContext";
import ReportTextInputs from "./ReportTextInputs";
import { useReports } from "context/ReportContext";

// TODO: Sometimes files withiin formState will just be strings, go back to files, go back to strings..., if error on upload the files will then only remain strings

export default function ReportPost({ setNewReportInput }) {
  const showNotification = useNotification();

  const [loading, setLoading] = useState(false);
  const editReportToken = useSelector((state) => state.editReportToken);
  const token = useSelector((state) => state.token);
  const { reports, setReports } = useReports();

  useGSAP(() => {
    gsap.fromTo(".inputs", defaultGsapFrom, defaultGsapTo);
  }, []);

  const [formState, setFormState] = useState({
    reportTitleGer: "",
    reportDescriptionGer: "",
    reportFileGer: null,
    reportTitleEng: "",
    reportDescriptionEng: "",
    reportFileEng: null,
  });

  function inputValidation(formState) {
    const {
      reportTitleGer,
      reportDescriptionGer,
      reportFileGer,
      reportTitleEng,
      reportDescriptionEng,
      reportFileEng,
    } = formState;

    if (!reportTitleGer || !reportTitleEng) {
      return "Both titles required.";
    }

    const gerDesc = reportDescriptionGer.trim() !== "";
    const engDesc = reportDescriptionEng.trim() !== "";
    if (gerDesc !== engDesc) {
      return "Both descriptions required.";
    }

    if (!reportFileGer && !reportFileEng) {
      return "At least one file required.";
    }

    return "";
  }

  function handleChange(e) {
    setFormState({
      ...formState,
      [e.target.name]:
        e.target.type === "file" ? e.target.files[0] : e.target.value,
    });
  }

  // Post req
  async function postNewReport() {
    const validationError = inputValidation(formState); // Mihajlo implement for editing
    if (validationError) {
      showNotification("error", validationError);
      return;
    }

    setLoading(true);
    // UX

    const formData = new FormData();
    formState.reportFileGer &&
      formData.append("pdfGer", formState.reportFileGer);
    formState.reportFileEng &&
      formData.append("pdfEng", formState.reportFileEng);

    // File names
    let formStateOnlyWithFilesNames = { ...formState };
    formStateOnlyWithFilesNames.reportFileGer = formState.reportFileGer?.name;
    formStateOnlyWithFilesNames.reportFileEng = formState.reportFileEng?.name;
    // File names

    formData.append("report", JSON.stringify(formStateOnlyWithFilesNames));

    try {
      const response = await fetch(`${serverUrl}/reports/post`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${editReportToken || token}`,
        },
        body: formData,
      });

      if (response.ok) {
        showNotification("success", "Erfolgreich hochgeladen");
        const report = await response.json();
        const currentDate = Date.now();

        setReports([
          ...reports,
          {
            ...report,
            createdAt: currentDate,
            updatedAt: currentDate,
          },
        ]);
        setFormState({
          reportTitleGer: "",
          reportDescriptionGer: "",
          reportFileGer: null,
          reportTitleEng: "",
          reportDescriptionEng: "",
          reportFileEng: null,
        });
        setNewReportInput(false);
      } else {
        const data = await response.json();
        showNotification("error", data.error || data.message);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  return (
    <form
      style={{ margin: "0 0 2rem" }}
      onSubmit={(e) => {
        e.preventDefault();
        postNewReport();
      }}
    >
      <Box className="inputs" display="flex" gap="1rem" m="1rem 0 0">
        <Typography variant="h4" sx={{ width: "100%" }}>
          Deutsche Version
        </Typography>
        <Typography variant="h4" sx={{ width: "100%" }}>
          English Version
        </Typography>
      </Box>
      <ReportTextInputs report={formState} handleChange={handleChange} />

      <Divider className="inputs" sx={{ m: "1rem 0" }} />
      <Box className="inputs" display="flex" gap="1rem" m="1rem 0 2rem">
        <ReportPdfInput
          file={formState.reportFileGer}
          handleChange={handleChange}
          language={"ger"}
        />
        <ReportPdfInput
          file={formState.reportFileEng}
          handleChange={handleChange}
          language={"eng"}
        />
      </Box>

      <Divider className="inputs" sx={{ mb: "2rem" }} />

      <Box className="inputs">
        <UploadCancelCMS setIsInput={setNewReportInput} loading={loading} />
      </Box>
    </form>
  );
}
