import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import LoginPage from "pages/LoginPage";
import Navbar from "components/Navbar";
import Projects from "pages/Projects";
import Footer from "components/Footer";
import Project from "pages/Project";
import Page from "pages/Page";
import CookieBanner from "components/CookieBanner";
import Reports from "pages/Reports";
import { NotificationProvider } from "context/NotificationContext";
import { ReportsProvider } from "context/ReportContext";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode), [mode])); // eslint-disable-line

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <CssBaseline />
          <Navbar />
          <Routes>
            <Route path="/" element={<Page pageTitle={"Home"} />} />
            <Route path="/loginAdmin" element={<LoginPage />} />
            <Route path="/about_us" element={<Page pageTitle={"Über uns"} />} />
            <Route path="/contact" element={<Page pageTitle={"Kontakt"} />} />
            <Route path="/projects">
              <Route index element={<Projects />} />
              <Route path=":projectName" element={<Project />} />
            </Route>

            <Route
              path="/reports"
              element={
                <ReportsProvider>
                  <Reports pageTitle={"Berichte"} />
                </ReportsProvider>
              }
            />

            <Route
              path="/legal-disclosure"
              element={<Page pageTitle={"Impressum"} />}
            />
            <Route
              path="/terms-of-service"
              element={<Page pageTitle={"TOS"} />}
            />
            <Route
              path="/privacy-policy"
              element={<Page pageTitle={"Datenschutzrichtlinie"} />}
            />
            <Route
              path="/cookie-policy"
              element={<Page pageTitle={"Cookies"} />}
            />
            <Route path="/dev" element={<Page pageTitle={"Development"} />} />
            <Route path="*" element={<Page pageTitle={"404"} />} />
          </Routes>
          <CookieBanner />
          <Footer />
        </NotificationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
